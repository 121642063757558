
import { Vue, Options } from 'vue-class-component';
import { i18n, supportedLocales, SupportedLocale } from '@/i18n/i18n';

import {
  BaseTextInput,
  BaseButton,
  BaseSelect,
  BaseCard,
  AvatarUpload
} from '@/lib/components';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';

@Options({
  components: {
    BaseCard,
    BaseSelect,
    BaseTextInput,
    BaseButton,
    AvatarUpload
  }
})
export default class ProfilePage extends Vue {
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  saving = false;

  givenName: string | null = '';

  familyName: string | null = '';

  email: string | null = '';
  errors: { [key: string]: Array<string> } = {};

  avatarId: string | null = '';
  avatarLoading = false;
  avatarComponentKey = 0;

  locale: string | null = '';

  get languages() {
    const languages = supportedLocales.map((supportedLocale: SupportedLocale) => ({
      value: supportedLocale.code,
      label: supportedLocale.name
    }));
    return isFeatureFlagEnabled(FEATURES.LANGUAGE_PREFERENCES) ?
      languages :
      languages.filter((supportedLocale) => supportedLocale.value === this.sessionStore.currentUser?.locale);
  }

  selectedLanguage: string = i18n.global.locale.value;

  beforeCreate() {
    this.givenName = this.sessionStore.currentUser?.given_name;
    this.familyName = this.sessionStore.currentUser?.family_name;
    this.email = this.sessionStore.currentUser?.email;
    this.avatarId = this.sessionStore.currentUser?.avatar_id;
    this.locale = this.sessionStore.currentUser?.locale;
  }

  async save() {
    this.saving = true;
    try {
      await this.sessionStore.saveUser({
        given_name: this.givenName,
        family_name: this.familyName,
        email: this.email,
        avatar_id: this.avatarId,
        locale: this.selectedLanguage
      });
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.update-success')
      });
    } catch (error) {
      this.errors = error.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.profile.update-error')
      });
    } finally {
      this.saving = false;
    }
  }

  cancel() {
    this.givenName = this.sessionStore.currentUser?.given_name;
    this.familyName = this.sessionStore.currentUser?.family_name;
    this.email = this.sessionStore.currentUser?.email;
    this.avatarId = this.sessionStore.currentUser?.avatar_id || '';
    this.selectedLanguage = this.sessionStore.currentUser?.locale || '';
  }

  handleAvatarLoading(value: boolean) {
    this.avatarLoading = value;
  }

  refreshAvatarComponent() {
    this.avatarComponentKey++;
  }
}
